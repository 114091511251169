export enum Routes {
  Root = '/',
  Register = '/register',
  RegisterOptions = '/register/options',
  Authorize = '/authorize',

  Dashboard = '/dashboard',
  Profile = '/dashboard/profile',
  Settings = '/dashboard/settings',

  LoginCallback = '/callback',
  UserActivation = '/activate',

  // itsme - One time activation
  ItsmeActivate = '/itsme/activate',
  ItsmeSignIn = '/itsme/sign-in',
  ItsmeCallback = '/itsme/callback',
  ItsmeError = '/itsme/error',
  ItsmeRegister = '/itsme/register',
  ItsmeSuccessfullyLinked = '/itsme/successfully-linked',
}
