import { Box, Typography } from '@material-ui/core';
import Page from 'components/@layout/Page';
import React, { useCallback, useEffect } from 'react';
import { Title, Wrap } from '../styles';
import { FormattedMessage } from 'react-intl';
import { ItsmeButton } from 'components/@okta';
import qs from 'query-string';
import { useOktaAuth } from '@okta/okta-react';
import { envService } from 'services';
import { encodeState } from 'utils/okta.utils';
import { Flows, Origins } from 'types/state.types';
import { AppStateService } from 'packages/app-state/app-state';

// This page is used to sign in with itsme in the linking app frontend directly,
// not via Okta. We then use this itsme session to link the Okta user (that should already be signed in)
// with the itsme sub. Note: this sub is fetched on the backend.
const SignInItsme = () => {
  const { oktaAuth } = useOktaAuth();

  const handleStoreTokens = useCallback(async () => {
    const { tokens } = await oktaAuth.token.parseFromUrl();

    if (tokens) {
      oktaAuth.tokenManager.setTokens(tokens);
    }
  }, [oktaAuth.token, oktaAuth.tokenManager]);

  useEffect(() => {
    handleStoreTokens();
  }, [handleStoreTokens]);

  // Get the local appstate from local storage
  const localAppState = AppStateService.getLocalAppState();

  // Change the flow of the state to the LinkAccount flow
  let appState;
  if (localAppState) {
    appState = encodeState({
      ...localAppState,
      flow: Flows.LinkAccount,
    });
  } else {
    // We fallback to web as that is the safest option if we do not have an appState
    appState = encodeState({
      flow: Flows.LinkAccount,
      origin: Origins.Web,
    });
  }

  return (
    <Page scroll="paper">
      <Wrap>
        <Box flex={1}>
          <Title variant="h1">
            <FormattedMessage id="itsme.sign_in.title" />
          </Title>
          <Typography
            style={{
              whiteSpace: 'pre-line',
            }}
            color="textSecondary"
          >
            <FormattedMessage
              id="itsme.sign_in.description"
              values={{
                b: (chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </Box>
        <ItsmeButton
          href={qs.stringifyUrl({
            url: envService.ITSME_CLIENT_URL,
            query: {
              client_id: envService.ITSME_CLIENT_ID,
              scope: envService.ITSME_SCOPE,
              response_type: 'code',
              redirect_uri: envService.ITSME_CALLBACK_URL,
              state: appState,
            },
          })}
        />
      </Wrap>
    </Page>
  );
};

export default SignInItsme;
