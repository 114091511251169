import { Box, CircularProgress, Container } from '@material-ui/core';
import { useUpdateUserCredentials } from 'queries';
import React, { useCallback, useEffect } from 'react';
import history from 'packages/history';
import { Routes } from 'pages/routes.constants';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import { decodeState } from 'utils/okta.utils';
import { envService } from 'services';
import { DecodedState, Flows, Origins } from 'types/state.types';
import { MissingOriginInState } from 'services/error/error.types';
import { AppStateService } from 'packages/app-state/app-state';
import {
  REGISTER_DEEPLINK,
  SUCCESSFULLY_LINKED_DEEPLINK,
} from 'constants/itsme';

const Callback: React.FC<RouteComponentProps> = () => {
  const { updateUserCredentials } = useUpdateUserCredentials();

  const handleUpdateOrRedirect = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code') ?? '';
    // This needs to be state since the callback is coming back from Okta or Itsme, so they will encode it into "state" and not "appState".
    // The "appState" param query is only there to make our duct-tape solution work.
    const state = urlParams.get('state') ?? '';
    const redirectUri = urlParams.get('redirectUri') ?? '';
    const decodedState = decodeState<DecodedState>(state);

    if (urlParams.has('error') && decodedState) {
      window.location.replace(
        qs.stringifyUrl({
          url: Routes.ItsmeError,
          query: {
            state,
            error_description: urlParams.get('error_description') ?? undefined,
          },
        }),
      );

      return;
    }

    if (urlParams.has('code') && decodedState) {
      if (!decodedState.origin) {
        throw new MissingOriginInState();
      }

      const baseUrl = envService.getCallbackItsmeBaseUrl(decodedState.origin);

      switch (decodedState.flow) {
        case Flows.RegisterForm: {
          if (decodedState.origin === Origins.App) {
            window.location.replace(
              qs.stringifyUrl({
                url: redirectUri,
                query: {
                  code,
                  state,
                },
              }),
            );
          } else if (decodedState.origin === Origins.Web) {
            /**
             * Because the web app can't handle the fact that we put `state` or `code` params in the URL
             * when it is not a login flow, we have to redirect to the redirectUri with `s` and `c` as query params.
             */
            window.location.replace(
              qs.stringifyUrl({
                url: redirectUri,
                query: {
                  c: code,
                  s: state,
                },
              }),
            );
          }

          break;
        }

        case Flows.Activate: {
          window.location.replace(
            qs.stringifyUrl({
              url: `${baseUrl}/authorization-code/callback`,
              query: { code, state },
            }),
          );
          break;
        }

        case Flows.LinkAccount: {
          updateUserCredentials(
            {
              type: 'ITSME',
              itsmeCode: code,
            },
            {
              onSuccess: () => {
                // Clear the app state from local storage just to be sure
                AppStateService.clearAppState();

                // Linking call to the API succeeded, take the user to the "Successfully Linked" screen.
                if (decodedState.origin === Origins.App) {
                  const appDeeplink = qs.stringifyUrl({
                    url: `${baseUrl}/${SUCCESSFULLY_LINKED_DEEPLINK}`,
                    query: {
                      state,
                      code,
                    },
                  });
                  window.location.replace(appDeeplink);
                } else {
                  history.push({
                    pathname: Routes.ItsmeSuccessfullyLinked,
                    search: qs.stringify({
                      state,
                      code,
                    }),
                  });
                }
              },
            },
          );
          break;
        }

        case Flows.Register: {
          if (decodedState.origin === Origins.App) {
            window.location.replace(
              qs.stringifyUrl({
                url: `${baseUrl}/${REGISTER_DEEPLINK}`,
                query: { code, state },
              }),
            );
          } else {
            history.push({
              pathname: Routes.Register,
              search: qs.stringify({
                code,
                state,
              }),
            });
          }
          break;
        }
      }
    }
  }, [updateUserCredentials]);

  useEffect(() => {
    handleUpdateOrRedirect();
  }, [handleUpdateOrRedirect]);

  return (
    <Container fixed>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={40} />
      </Box>
    </Container>
  );
};

export default Callback;
