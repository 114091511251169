import { DecodedState } from 'types/state.types';

/**
 * Utility class that helps getting the app state from the URL params and stores it locally in localStorage.
 * The app state contains what flow we are running and what the origin was (web or app).
 *
 * It's a base64 encoded object that looks like this: { 'flow': 'register', 'origin': 'app' }.
 *
 * The flow goes as follows:
 *
 * 1. The user opens the in app browser with an appState query param in the URL
 * 2. The okta widget will read that appState and put it into cookies
 * 3. When the user logs in with itsme for the first time (thus triggering a linking flow), Okta will trigger an error page because the itsme identity is not known
 * 4. On this error page we will read the appState from the cookie and redirect into the Identity platform on the url: /itsme/activate?appState=...
 * 5. With this class we retrieve it from the URL
 * 6. Once we retrieve it we can work with it in the Activate page. We take it from the URL and store it in localStorage
 * 7. When we log the user in for the second time with email/pw and they come back to the identity platform, we have the appState in localStorage
 * 8. We then ask the user to sign in with itsme again and we will encode the appState into the **state** param we send to itsme
 * 9. Itsme will return to okta and okta to our itsme callback page, where we will read the **state** param from the URL (very important here is that since we are working with an OAuth2.0 flow, the state will be returned to us from okta in the state param and not appState like we do in the beginning. appState is just a hack we use to pass custom state to the error page thrown by okta)
 */
export class AppStateService {
  static getAppStateFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get(APP_STATE_QUERY_PARAM);

    return state;
  }

  /**
   * Store the app state locally
   */
  static storeAppState(state: string) {
    window.localStorage.setItem(APP_STATE_LOCALSTORAGE_KEY, state);
  }

  /**
   * Get the locally stored app state.
   * Returns the state as an object.
   *
   * @returns appState object
   */
  static getLocalAppState(): DecodedState | null {
    const state = window.localStorage.getItem(APP_STATE_LOCALSTORAGE_KEY);
    if (state) {
      try {
        const decoded = atob(state);
        return JSON.parse(decoded);
      } catch (error) {
        console.error('Decoding local appstate failed: ', error);
        return null;
      }
    }

    return null;
  }

  /**
   * Clear the app state from localstorage
   */
  static clearAppState() {
    window.localStorage.removeItem(APP_STATE_LOCALSTORAGE_KEY);
  }
}

/** name of the app state query param */
const APP_STATE_QUERY_PARAM = 'appState';

/** name of the app state local storage key */
const APP_STATE_LOCALSTORAGE_KEY = 'appState';
