import { UnknownOriginInState } from 'services/error/error.types';
import { Origins } from 'types/state.types';

export interface EnvApiConfiguration {
  IDP_ISSUER: string;
  IDP_CLIENT_ID: string;
  IDP_BASE: string;
  TRACK_GA_KEY: string;
  API_BASE: string;
  LOCALSTORAGE_ID: string;
  VRB_AUTH_CODE: string;
  ITSME_CLIENT_ID: string;
  ITSME_CLIENT_URL: string;
  ITSME_CALLBACK_URL: string;
  ITSME_SCOPE: string;
  OKTA_CALLBACK_URL: string;
  ITSME_OKTA_CLIENT_ID: string;
  VRB_WEB_PORTAL_URL: string;
}

export interface EnvBaseConfiguration {
  NAME?: string;
  VERSION?: string;
  BUILD_ENV?: string;
  ENABLE_DEV_TOOLS?: boolean;
  PORT?: number;
  DEFAULT_CURRENCY?: string;
  DEFAULT_LOCALE?: string;
  DEFAULT_LOCALE_DATE?: string;
}

class EnvService {
  NAME = '';
  VERSION = '';
  BUILD_ENV = '';
  ENABLE_DEV_TOOLS = true;
  PORT = 3000;
  DEFAULT_CURRENCY = 'EUR';
  DEFAULT_LOCALE = 'en';
  DEFAULT_LOCALE_DATE = 'nl';

  IDP_ISSUER = '';
  IDP_CLIENT_ID = '';
  IDP_BASE = '';
  TRACK_GA_KEY = '';
  API_BASE = '/';
  LOCALSTORAGE_ID = 'vrb-identity';
  ITSME_CLIENT_ID = '';
  ITSME_CLIENT_URL = '';
  ITSME_SCOPE = '';
  ITSME_CALLBACK_URL = '';
  OKTA_CALLBACK_URL = '';
  ITSME_OKTA_CLIENT_ID = '';
  VRB_AUTH_CODE = '';

  // This value is the same for all environments in the app
  private APP_CALLBACK_ITSME_BASE_URL = 'vanbredahc:/';
  // This value comes from the env file
  private WEB_CALLBACK_ITSME_BASE_URL = '';

  constructor(config: EnvBaseConfiguration) {
    this.NAME = config.NAME ?? this.NAME;
    this.VERSION = config.VERSION ?? this.VERSION;

    this.BUILD_ENV = config.BUILD_ENV ?? this.BUILD_ENV;
    this.ENABLE_DEV_TOOLS =
      Boolean(config.ENABLE_DEV_TOOLS) ?? this.ENABLE_DEV_TOOLS;

    this.PORT = Number(config.PORT) ?? this.PORT;
    this.DEFAULT_CURRENCY = config.DEFAULT_CURRENCY ?? this.DEFAULT_CURRENCY;
    this.DEFAULT_LOCALE = config.DEFAULT_LOCALE ?? this.DEFAULT_LOCALE;
    this.DEFAULT_LOCALE_DATE =
      config.DEFAULT_LOCALE_DATE ?? this.DEFAULT_LOCALE_DATE;
  }

  /**
   * Get the correct callback base url based on the origin in the auth state query param.
   * If the origin is undefined we use the app callback url as default.
   *
   * - For web we return an url like: `https://app.{env}.vanbreda.be/callbacks`
   * - For app we return an url like: `vanbredahc:/` (Note the single slash, this is on purpose to make the code
   *                                                  more readable where we concat the rest of the redirect urls)
   *
   * Note: the param _origin is prefixed with _ because "origin" already exists in the global scope (it's equal to window.origin)
   *
   * @param _origin
   * @returns
   */
  getCallbackItsmeBaseUrl(_origin: Origins) {
    if (_origin === Origins.App) {
      return this.APP_CALLBACK_ITSME_BASE_URL;
    } else if (
      _origin === Origins.Web &&
      this.WEB_CALLBACK_ITSME_BASE_URL.length > 0
    ) {
      return this.WEB_CALLBACK_ITSME_BASE_URL;
    }

    throw new UnknownOriginInState();
  }

  setApiConfiguration = (config: EnvApiConfiguration) => {
    this.IDP_ISSUER = config.IDP_ISSUER ?? this.IDP_ISSUER;
    this.IDP_CLIENT_ID = config.IDP_CLIENT_ID ?? this.IDP_CLIENT_ID;
    this.IDP_BASE = config.IDP_BASE ?? this.IDP_BASE;
    this.TRACK_GA_KEY = config.TRACK_GA_KEY ?? this.TRACK_GA_KEY;
    this.API_BASE = config.API_BASE ?? this.API_BASE;
    this.LOCALSTORAGE_ID = config.LOCALSTORAGE_ID ?? this.LOCALSTORAGE_ID;
    this.VRB_AUTH_CODE = config.VRB_AUTH_CODE ?? this.VRB_AUTH_CODE;
    this.ITSME_CLIENT_ID = config.ITSME_CLIENT_ID ?? this.ITSME_CLIENT_ID;
    this.ITSME_CLIENT_URL = config.ITSME_CLIENT_URL ?? this.ITSME_CLIENT_URL;
    this.ITSME_CALLBACK_URL =
      config.ITSME_CALLBACK_URL ?? this.ITSME_CALLBACK_URL;
    this.OKTA_CALLBACK_URL = config.OKTA_CALLBACK_URL ?? this.OKTA_CALLBACK_URL;
    this.ITSME_OKTA_CLIENT_ID =
      config.ITSME_OKTA_CLIENT_ID ?? this.ITSME_OKTA_CLIENT_ID;
    this.ITSME_SCOPE = config.ITSME_SCOPE ?? this.ITSME_SCOPE;
    this.WEB_CALLBACK_ITSME_BASE_URL = config.VRB_WEB_PORTAL_URL
      ? `${config.VRB_WEB_PORTAL_URL}/callbacks`
      : this.WEB_CALLBACK_ITSME_BASE_URL;
  };
}

export default EnvService;
